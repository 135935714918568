<template>
  <section class="order-preview-wrapper">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="orderData === undefined">
      <h4 class="alert-heading">
        {{ $t("xFetchError", { value: $t("order") }) }}
      </h4>
      <div class="alert-body">
        {{ $t("xNotFound", { value: $t("order") }) }}
        <b-link class="alert-link" :to="{ name: 'orders' }" />
      </div>
    </b-alert>

    <b-row v-if="orderData" class="order-preview">
      <!-- Col: Left (Order Container) -->
      <b-col cols="12" xl="12" md="12">
        <b-card no-body class="order-preview-card">
          <!-- Header -->
          <b-card-body class="order-padding pb-0">
            <div
              class="d-flex flex-column flex-sm-row mt-0 justify-content-center align-items-center align-items-md-start"
            >
              <!-- Header: Left Content -->
              <div class="d-flex logo-wrapper position-relative">
                <logo class="" style="top: 7px" height="75" />
              </div>

              <!-- Header: Right Content -->
              <div
                class="mt-md-0 mt-2 flex-grow-1 d-flex flex-column align-items-center justify-content-end align-items-sm-end"
              >
                <div class="justify-content-center flex-shrink-1 mb-1">
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-danger"
                    @click="deleteOrder()"
                  >
                    <feather-icon icon="TrashIcon" />
                    {{ $t("delete") }}
                  </b-button>
                </div>

                <div class="d-flex flex-md-row flex-column">
                  <h4 class="order-title align-end mr-2">
                    {{ $t("order") }} No:
                    <span class="order-number">#{{ orderData.id }}</span>
                  </h4>
                  <div class="mr-2">
                    <p class="font-weight-bold">{{ $t("orderDate") }}:</p>
                    <p class="order-date">
                      {{
                        dayjs(orderData.orderDate)
                          .locale($i18n.locale)
                          .format("DD MMMM YYYY")
                      }}
                    </p>
                  </div>

                  <div class="order-date-wrapper mr-2">
                    <p class="order-date-title font-weight-bold">
                      {{ $t("dueDate") }}:
                    </p>
                    <p class="order-date">
                      {{
                        dayjs(orderData.duedate)
                          .locale($i18n.locale)
                          .format("DD MMMM YYYY")
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="order-spacing" />

          <!-- Order Client & Payment Details -->
          <b-card-body v-if="orderData.client" class="order-padding pt-0">
            <b-row class="order-spacing">
              <!-- Col: Order To -->
              <b-col cols="12" xl="6" class="p-0">
                <h6 class="mb-2">Order To:</h6>
                <h6 class="mb-25">
                  {{ orderData.client.name }}
                </h6>
                <p class="card-text mb-25">
                  {{ orderData.client.company }}
                </p>
                <p class="card-text mb-25">
                  {{ orderData.client.address }},
                  {{ orderData.client.country }}
                </p>
                <p class="card-text mb-25">
                  {{ orderData.client.contact }}
                </p>
                <p class="card-text mb-0">
                  {{ orderData.client.companyEmail }}
                </p>
              </b-col>
            </b-row>
          </b-card-body>
          <!-- Order Description: Table -->
          <b-table-lite
            v-if="items"
            responsive
            :items="items"
            :fields="[
              { key: 'kod', label: this.$t('code') },
              { key: 'productname', label: this.$t('productName') },
              { key: 'orderunit', label: this.$t('unit') },
              { key: 'orderamount', label: this.$t('amount') },
              { key: 'price', label: this.$t('price') },
              { key: 'termname', label: this.$t('term') },
            ]"
          >
            <template #cell(taskDescription)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ data.item.taskTitle }}
              </b-card-text>
              <b-card-text class="text-nowrap">
                {{ data.item.taskDescription }}
              </b-card-text>
            </template>
          </b-table-lite>

          <!-- Order Description: Total -->
          <b-card-body class="order-padding pb-0">
            <b-row>
              <!-- Col: Sales Persion -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-0 mt-3"
                order="2"
                order-md="1"
              >
                <b-card-text class="mb-0">
                  <span class="font-weight-bold">{{ $t("addUser") }}:</span>
                  <span v-if="orderData" class="ml-75">{{
                    orderData.adduser
                  }}</span>
                </b-card-text>
              </b-col>

              <!-- Col: Total -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-6 d-flex justify-content-end"
                order="1"
                order-md="2"
              >
                <div class="order-total-wrapper">
                  <div class="order-total-item">
                    <p class="order-total-title">{{ $t("totalPrice") }}:</p>
                    <p v-if="items" class="order-total-amount">
                      {{
                        items
                          .map((price) => price.price * price.orderamount)
                          .reduce((a, b) => a + b)
                      }}
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Spacer -->
          <hr class="order-spacing" />

          <!-- Note -->
          <b-card-body class="order-padding pt-0">
            <span class="font-weight-bold">{{ $t("note") }}: </span>
            <span>{{ orderData.additionalinfo }}</span>
          </b-card-body>
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <!-- <b-col cols="12" md="3" xl="3" class="order-actions">
        <b-card>
           Button: Edit 
          <b-button
            v-if="false"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            :to="{
              name: 'apps-order-edit',
              params: { id: $route.params.id },
            }"
          >
            {{ $t("edit") }}
          </b-button>

        

          Button: Add Payment
          <b-button
            v-b-toggle.sidebar-order-add-payment
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            class="mb-75"
            block
          >
    {{ $t("add") }} Payment
          </b-button> 
        </b-card>
      </b-col> -->
    </b-row>

    <!-- <order-sidebar-add-payment /> -->
  </section>
</template>
<script>
import VueI18n from "@/libs/i18n";
import { ref } from "@vue/composition-api";
import store from "@/store";
import router from "@/router";
import Logo from "@core/layouts/components/Logo.vue";

import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BLink,
  VBToggle,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import dayjs from "dayjs";
export default {
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    Logo,
  },
  beforeRouteEnter(to, from, next) {
    if (to.name == "plantOrderPreview") {
      to.meta.breadcrumb = [];
      to.meta.breadcrumb.push({
        to: "/plants",
        text: VueI18n.t("plants"),
      });
      to.meta.breadcrumb.push({
        text: VueI18n.t("plant"),
        to: `/plant/${to.params.id}`,
        active: false,
      });
      to.meta.breadcrumb.push({
        text: VueI18n.t("plantOrders"),
        to: `/plant/${to.params.plantid}/orders`,
        active: false,
      });
      to.meta.breadcrumb.push({
        text: VueI18n.t("order"),
        active: true,
      });
    }
    next();
  },
  setup() {
    const orderData = ref(null);
    const paymentDetails = ref({});
    const items = ref(null);

    // Order Description
    // ? Your real data will contain this information

    const getItems = async () =>
      (items.value = await store.dispatch(
        "ordersModule/fetchOrderItems",
        router.currentRoute.params.id
      ));
    getItems();
    store
      .dispatch("ordersModule/fetchOrders")
      .then(async (response) => {
        orderData.value = response.filter(
          (r) => r.id == router.currentRoute.params.id
        )[0];
        paymentDetails.value = {};
      })
      .catch((error) => {
        if (error.response.status === 404) {
          orderData.value = undefined;
        }
      });

    const deleteOrder = async () => {
      await store.dispatch(
        "ordersModule/deleteOrder",
        router.currentRoute.params.id
      );
      await router.push({ name: "orders" });
    };

    const printOrder = () => {
      window.print();
    };

    return {
      orderData,
      paymentDetails,
      items,
      printOrder,
      dayjs,
      deleteOrder,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Order Specific Styles
  .order-preview-wrapper {
    .row.order-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .order-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }
    .order-title {
      justify-self: end;
    }
    // Action Right Col
    .order-actions {
      display: none;
    }
  }
}
</style>
